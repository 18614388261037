<template>
	<div id="line_site">
		<jy-query ref="form" :model="form" label-width="72px">
			<!-- <jy-query-item prop="bianhao" label="场站编号:">
                    <el-input placeholder="请输入" v-model="form.bianhao"></el-input>
                </jy-query-item>
                <jy-query-item prop="xname" label="场站名称:">
                    <el-input placeholder="请输入" v-model="form.xname"></el-input>
                </jy-query-item>-->
			<jy-query-item prop="stationName" label="场站组织:">
				<div @click="selectInstitutions">
					<el-input placeholder="请选择" v-model="form.stationName"></el-input>
				</div>
				<!-- <el-cascader
                    v-model="form.stationName"
                    placeholder="请选择"
                    :options="siteLineList"
                    :props="props"
                    :show-all-levels="false"
                    filterable
                    clearable
                ></el-cascader>-->
			</jy-query-item>
			<!-- <jy-query-item prop="vehicleNo" label="车牌号:">
                <el-input placeholder="请输入" v-model="form.vehicleNo"></el-input>
            </jy-query-item>
            <jy-query-item prop="state" label="状态:">
                <el-select v-model="form.state" placeholder="请选择">
                    <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
            </jy-query-item>-->
			<template slot="search">
				<el-button type="primary" @click="oncheck">查询</el-button>
				<el-button type="primary" plain @click="resetForm('form')">重置</el-button>
				<!-- <el-row>
                </el-row> -->
			</template>
			<template slot="operate">
				<el-button type="primary" v-on:click="addUdate()">新增</el-button>
				<el-button type="primary" @click="batchdelete">批量删除</el-button>
				<!-- <el-button type="primary" @click="onexport">导出</el-button>
                                <el-button type="primary" @click="onimport">导入</el-button>-->
			</template>
		</jy-query>
		<jy-table
			max-height="595"
			ref="multipleTable"
			tooltip-effect="dark"
			@select-all="selectallchange"
			@select="selectchange"
			:data="dataList"
		>
			<jy-table-column type="index" label="序号" width="60" align="center"></jy-table-column>
			<jy-table-column type="selection" width="40"></jy-table-column>
			<jy-table-column prop="stationNo" label="场站编号" min-width="120"></jy-table-column>
			<jy-table-column prop="stationName" label="场站名称" min-width="120"></jy-table-column>
			<jy-table-column prop="stationType" label="场站类型" min-width="120"></jy-table-column>
			<jy-table-column prop="orgName" label="所属机构" min-width="180"></jy-table-column>
			<jy-table-column prop="remark" label="备注" class-name="align_left" min-width="200"></jy-table-column>
			<jy-table-column prop="createTime" label="创建日期" min-width="160"></jy-table-column>
			<jy-operate v-bind:list="operateList" width="200"></jy-operate>
		</jy-table>
		<jy-pagination
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="pageIndex"
			:page-size="pageSize"
			:total="total"
		></jy-pagination>
		<add-update-depotcar ref="addUpdateOrg" @updateList="getList"></add-update-depotcar>
		<institutions-tree
			ref="institutions"
			selectType="2"
			@addInstitutions="addInstitutions"
			url="/stationRelVehicle/getStationTree"
			title="选择场站组织"
			:defaultProps="props"
		></institutions-tree>
	</div>
</template>
<script>
import addUpdateDepotcar from "@/components/pages/admin/resourcesAllocation/depotCar/addUpdateDepotcar";
import institutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";
export default {
	data() {
		return {
			siteLineList: [],
			props: {
				children: "children",
				label: "name"
			},
			statusList: [
				{
					label: "全部",
					value: -1
				},
				{
					label: "启用",
					value: 1
				},
				{
					label: "禁用",
					value: 0
				}
			],
			form: {
				stationId: "",
				stationName: ""
				// vehicleNo: '',
				// state: -1
			},
			result: {},
			batchdeleteList: [],
			pageSize: 10,
			total: 100,
			pageIndex: 1,
			dataList: [],
			operateList: []
		};
	},
	watch: {
		"form.stationName": function (val, oldVal) {
			if (val != oldVal) {
				if (this.form.stationName == "") {
					this.form.stationId = "";
				}
				this.getList();
			}
		}
	},

	components: {
		addUpdateDepotcar,
		institutionsTree
	},

	computed: {},

	activated() {},

	created() {
		this.getList();
		this.setOperateList();
	},

	methods: {
		setOperateList() {
			let l = [
				{
					name: "修改",
					icon: "el-icon-edit-outline",
					fun: this.addUdate
				},
				{
					name: "删除",
					icon: "el-icon-delete",
					fun: this.handleDelete
				},
				{
					name: "查看",
					icon: "el-icon-view",
					fun: this.handlecheck
				}
			];
			this.operateList = l;
		},
		// 组织
		selectInstitutions() {
			this.$refs.institutions.init();
		},
		addInstitutions(data) {
			console.log(data);
			if (data.stationId) {
				this.form.stationId = data.stationId;
			}
			this.form.stationName = data.name;
		},
		getList() {
			let option = {
				...this.form,
				pageIndex: this.pageIndex,
				pageSize: this.pageSize
			};
			let url = "/stationRelVehicle/getList";
			this.$http.post(url, option).then(({ detail }) => {
				// console.log(detail)
				this.dataList = detail.data;
				this.total = detail.totalPages;
				this.batchdeleteList = [];
			});
		},
		// 查询表格
		oncheck() {
			this.pageIndex = 1;
			this.getList();
			console.log("更新");
		},
		// 重置
		resetForm(formName) {
			this.$refs[formName].resetFields();
			this.oncheck();
		},
		// 分页
		handleSizeChange(val) {
			this.pageSize = val;
			this.getList();
		},
		handleCurrentChange(val) {
			this.pageIndex = val;
			this.getList();
		},
		// 勾选数据
		selectchange(selection) {
			console.log(selection);
			this.batchdeleteList = selection;
		},
		selectallchange(selection) {
			console.log(selection);
			this.batchdeleteList = selection;
		},
		addUdate(option) {
			if (option) {
				this.$refs.addUpdateOrg.init(option, "update");
			} else {
				//新增
				// if (this.form.stationName) {
				this.$refs.addUpdateOrg.init(this.form, "add");
				// } else {
				//   this.$alert('请先选中线路！', '提示', {
				//     type: 'warning'
				//   })
				// }
			}
		},
		handlecheck(option) {
			this.$refs.addUpdateOrg.init(option, "view");
		},
		// 删除
		handleDelete(row) {
			this.$confirm("是否删除该场站车辆分配?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			})
				.then(() => {
					let option = [row];
					this.deleteRecord(option);
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消删除"
					});
				});
		},
		// 批量删除
		batchdelete() {
			if (this.batchdeleteList.length == 0) {
				this.$message({
					type: "warning",
					message: "请先选取数据"
				});
			} else {
				this.$confirm("是否删除所选中的场站车辆分配?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				})
					.then(() => {
						let option = this.batchdeleteList;
						this.deleteRecord(option);
					})
					.catch(() => {
						this.$message({
							type: "info",
							message: "已取消删除"
						});
					});
			}
		},
		deleteRecord(supplierIdAry) {
			this.$http.post("/stationRelVehicle/delete", supplierIdAry).then(data => {
				if (data.code == "0") {
					this.$message.success(data.msg);
					this.getList();
				} else {
					this.$message.error(data.msg);
				}
			});
		},
		onexport() {
			console.log("onexport");
		},
		onimport() {
			console.log("onimport");
		}
	}
};
</script>
<style lang="scss" scoped></style>
